:root {
  --accent-font: 'Tangerine', cursive, serif;
  --small-font-size: 0.79em;
  --base-color: #0D0D0D;
  --secondary-color: #444;
  --off-white: #f5f5f5;
  --grey: #bfbfbf;
  --primary: #F2BBD5;
  --primary-dark: #de93b7;
  --primary-darker: #bf4565;
  --default-margin: 1.2rem;
  --max-text-width: 48em;
  --default-shadow:  1px 1px 4px 0 #bbb;
  --frame-shadow: 0 0 0 0.1875em var(--off-white), 0 0 0 0.3125em var(--base-color), 0 0 0 0.75em var(--off-white), 0 0 0 1.25em var(--primary);
}

html {
  box-sizing: border-box;
  font-size: 100%;
  font-size: clamp(100%, 1.25vw, 150%);
  line-height: 1.5;
  color: var(--base-color);
}

*, *:before, *:after {
  box-sizing: inherit;
}

html,
body,
[id="root"] {
  height: 100%;
}

body {
  margin: 0;
  background-color: var(--off-white);
  background-image: linear-gradient(to right, #f9f9f9, #f9f9f9 2px, #f7f7f7 2px, #f2f2f2 50%, #ededed 50%, #f3f3f3);
  background-size: clamp(2.35em, 5vw, 2.5em) 100%;
  font-family: 'Work Sans', sans-serif;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

hr {
  margin: var(--default-margin) calc(var(--default-margin) * 2);
}

a {
  color: var(--primary-darker);
  text-decoration: none;
}

a:hover,
a:focus,
a:active {
  margin-bottom: -0.1em;
  border-bottom: 0.1em solid;
}

strong {
  font-weight: 500;
}

img {
  vertical-align: middle;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  margin-bottom: calc(var(--default-margin)/2);
  line-height: normal;
  font-weight: 400;
}

p {
  max-width: var(--max-text-width);
}

p, ul, dl, address {
  margin: 0;
  margin-bottom: var(--default-margin);
}

dt, dd {
  display: inline-block;
  margin: 0;
}

dt {
  min-width: 8em;
  font-size: var(--small-font-size);
  text-transform: uppercase;
}

article + article {
  margin-top: calc(var(--default-margin) * 3);
}

details {
  max-width: var(--max-text-width);
  margin-bottom: 0.25em;
  padding: 0.15em 1em;
  box-shadow: var(--default-shadow);
  background-color: var(--off-white);

}

summary {
  margin-left: -1em;
  cursor: pointer;
}

summary,
details[open] > * {
  padding-left: 0.25em;
}

details[open] > ul {
  padding-left: 2em;
}

[open] > summary {
  border-bottom: 1px solid #ddd;
  margin-bottom: 0.15em;
}

summary > * {
  display: inline-block;
  margin-bottom: 0;
  vertical-align: -0.1em;
}

.app-h1 {
  font-family: var(--accent-font);
  font-size: 3.25em;
}

.app-h2 {
  font-size: 1.602em;
  font-weight: 300;
  color: var(--primary-darker);
}

.app-h3 {
  font-size: 1.424em;
}

.app-h4 {
  font-size: 1.266em;
}

.app-h5 {
  font-size: 1.125em;
}

.app-h6 {
  font-size: 1em;
}

.app-lead-paragraph {
  font-size: 1.266em;
  color: var(--primary-darker);
  margin-top: calc(var(--default-margin) * -0.5);
}

.app-supplimental-text {
  color: var(--secondary-color);
  font-style: italic;
  font-size: var(--small-font-size);
}

.app-list {
  list-style: none;
  padding: 0;
}

.button-link {
  background: none;
  border: none;
  padding: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  color: var(--primary-darker);
  text-align: left;
  cursor: pointer;
}

.button-link:focus,
.button-link:hover {
  text-decoration: underline;
}

.lowercase {
  text-transform: lowercase;
}

[id="root"] {
  display: grid;
  grid-template-rows: auto 1fr auto;
  overflow: hidden;
}

main {
  padding: var(--default-margin) calc(var(--default-margin) * 2);
  overflow: auto;
}
