.app-header {
  background-image: radial-gradient(var(--off-white) .2em, transparent .2em), 
                    radial-gradient(var(--off-white) .2em, transparent .2em);
  background-color: var(--base-color);
  background-position: 0 0, 1em 1em;
  background-size: 2em 2em;
  font-family: var(--accent-font);
  font-size: clamp(1rem, 2vw, 1.8rem);
}

.app-header-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0.3em;
}

.app-header a {
  color: inherit;
  text-decoration: none;
  text-align: center;
  line-height: normal;
}

.app-header .app-main-nav {
  flex-grow: 1;
  display: none;
  justify-content: space-around;
  margin: 0 0.3em;
}

.app-header .menu-action,
.app-header .app-main-nav a {
  border-radius: 50%;
  margin: 0.3em;
  padding: 0.5em;
  background-color: var(--primary);
  border: 3px dotted;
  box-shadow: 2px 0 0 4px var(--primary);
  font-size: 1.2em;
  font-weight: 700;
  color: inherit;
  cursor: pointer;
}

.app-header .menu-action:focus,
.app-header .menu-action:hover,
.app-header .app-main-nav a:focus,
.app-header .app-main-nav a:hover,
.app-header .app-main-nav a:active {
  background-color: var(--primary-dark);
  box-shadow: 2px 0 0 4px var(--primary-dark);
  text-decoration: none;
}

.app-header .menu-action {
  margin-left: auto;
  padding: 0.35em 0.5em;
  font-size: 1.25em;
  box-shadow: 0 0 0 4px var(--primary);
}

.app-header .menu-action:focus,
.app-header .menu-action:hover {
  box-shadow: 0 0 0 4px var(--primary-dark);
}

.app-header .close-menu-action {
  float: right;
  border: none;
  background: none;
  margin-top: -0.5em;
  margin-bottom: 0.75em;
  padding: 0.75em;
  cursor: pointer;
}

.app-header .close-menu-action .fa-times {
  margin-left: .15em;
  font-size: 1.75em;
  vertical-align: -0.25em;
}

.app-header .app-popover-nav {
  position: absolute;
  z-index: 1;
  top: 0.5em;
  right: 0.5em; 
  width: 96%;
  height: 90vh;
  max-width: 25em;
  padding: 1em 0.6em;
  background-color: var(--off-white);
  box-shadow: -2px 2px 5px #aaa;
}

.app-header .app-popover-nav a {
  display: block;
  padding: 0.25em 1em;
  border-top: 1px solid var(--grey);
  font-family: 'Work Sans', sans-serif;
  font-weight: inherit;
  font-size: 1.5em;
  line-height: inherit;
  text-align: left;
  text-decoration: none;
  clear: right;
}

.app-header .app-popover-nav a:hover,
.app-header .app-popover-nav a:focus {
  background-color: var(--grey);
}

.app-header .logo {
  display: inline-block;  
  border-radius: 50%;
  border: 4px dotted var(--primary-darker);
  box-shadow: 2px 0 0 4px var(--off-white);
  margin-right: 0.3em;
  padding: 0.2em 0.5em 0 0.25em;
  background-color: var(--off-white);
  font-size: 2em;
  line-height: 0.8;
}

.app-header .logo small {
  display: block;
  font-size: 0.7em;
  line-height: 0.6;
}

.app-header .logo:hover,
.app-header .logo:focus,
.app-header .logo:active {
  text-decoration: none;
  margin-bottom: 0;
}

.app-header .poodle-icon {
  display: none;
  position: absolute;
  z-index: 1;
  top: clamp(-1.5em, -3vw, -1em);
  right: clamp(-0.6em, -1.4vw, -0.5em);
  width: clamp(1em, 19vw, 8em);
  height: clamp(1em, 17vw, 7.25em);
}

.app-sub-nav {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin: calc(var(--default-margin) * -1) calc(var(--default-margin) * -2);
  margin-bottom: var(--default-margin);
  padding: 0.15rem 1vw;
  background-color: var(--grey);
  font-size: 1.2em;
}

.app-sub-nav a {
  padding: 0 0.35em;
  color: inherit;
  white-space: nowrap;
}

.app-sub-nav .active {
  font-weight: bold;
}

.app-back-sub-nav {
  justify-content: start;
  padding: 0.15rem var(--default-margin);
}

.app-back-sub-nav .button-link {
  color: inherit;
}

.app-back-sub-nav .button-link:hover {
  text-decoration: none;
}

.app-footer {
  display: flex;
  align-items: center;
  padding: 0.5em 0.75em;
  background-color: var(--base-color);
  color: var(--off-white);
  font-size: var(--small-font-size);
}

.app-footer .fa-paw {
  font-size: 2em;
  margin-right: 0.3em;
  color: var(--primary);
}

.footer-nav {
  margin-left: auto;
}

.footer-nav a {
  color: inherit;
  text-decoration: none;
}

.footer-nav a:hover,
.footer-nav a:focus,
.footer-nav a:active {
  margin-bottom: 0;
  border-bottom: none;
}

.footer-nav-description {
  display: none;
}

.app-action-icon {
  display: inline-block;
  height: 1.7em;
  width: 1.7em;
  border-radius: 50%;
  border: 2px dotted;
  box-shadow: 0 0 0 2px var(--primary);
  margin: 0 0.35em 0 1em;
  background-color: var(--primary);
  font-size: 1.2em;
  text-align: center;
  color: var(--base-color);
  cursor: pointer;
}

a:focus > .app-action-icon,
a:hover >  .app-action-icon,
a:active > .app-action-icon {
  background-color: var(--primary-dark);
  box-shadow: 0 0 0 3px var(--primary-dark);
}

@media only screen and (min-width: 750px) {
  .app-header-wrapper {
    padding-right: clamp(1em, 15.6vw, 7em);
  }

  .app-header .app-main-nav {
    display: flex;
  }

  .app-header .app-popover-nav {
    display: none;
  }

  .app-header .poodle-icon,
  .footer-nav-description {
    display: inline-block;
  }

  .app-header .menu-action {
    display: none;
  }
}

.app-wait {
  text-align: center;
}

.app-card {
  max-width: var(--max-text-width);
  border-radius: 0.25em;
  box-shadow: var(--default-shadow);
  margin-bottom: var(--default-margin);
  padding: 1em;
  background-color: var(--off-white);
}

.app-card > :last-child {
  margin-bottom: 0;
}

.app-gallery {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(14em, 1fr));
  grid-auto-rows: minmax(18em, 25vh);
  grid-gap: var(--default-margin);
  margin-bottom: var(--default-margin);
}

.app-gallery > *,
.app-gallery > button {
  max-width: 35em;
  background-color: var(--off-white);
  box-shadow: 0 0 5px 2px #bbb;
  outline: none;
  transition: all .2s ease-in-out;
}

.app-gallery > a:hover,
.app-gallery > button:hover {
  box-shadow: 0 0 5px 3px #aaa;
  transform: scale(1.005);
}

.app-gallery a {
  position: relative;
  font-size: 1.125em;
  font-weight: 400;
}

.app-gallery figure,
.app-gallery img {
  width: 100%;
  height: 100%;
  margin: 0;
}

.app-gallery img {
  object-fit: cover;
}

.app-gallery figcaption {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0.25em;
}

.app-gallery .banner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  text-align: center;
  background: rgb(0, 0, 0, .6);
  color: #fff;
  padding: 0.25em 0;
  font-weight: bold;
}

.app-gallery.fill {
  grid-template-columns: repeat(auto-fill, minmax(14em, 1fr));
}

.app-gallery.contain {
  grid-auto-rows: auto;
}

.app-gallery.contain img {
  object-fit: contain;
}

.app-gallery.captioned {
  grid-auto-rows: minmax(21em, 25vh);
}

.app-gallery.show-placeholder img {
  background: var(--off-white) url('images/imageplaceholder.png') no-repeat center;
  background-size: 60%;
}

.app-gallery figure img {
  height: calc(100% - 2.1em);
}

.app-gallery .button-link {
  color: inherit;
}

.app-gallery .button-link:hover,
.app-gallery .button-link:focus {
  text-decoration: none;
}

.app-gallery-full-image {
  max-width: none;
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: var(--default-margin);
  background: rgba(0, 0, 0, .8);
}

.app-gallery-full-image figure, 
.app-gallery-full-image figure img {
  height: auto;
  width: auto;
  max-height: 90vh;
  max-width: 90vw;
  box-shadow: 0 0 10px 0 var(--base-color);
}

.app-gallery-image-container {
  position: relative;
}

.app-gallery-full-image figcaption {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 0.5em;
  background: rgba(0, 0, 0, .6);
  color: #fff;
  font-weight: bold;
  white-space: normal;
}

.app-gallery-full-image button {
  position: absolute;
  top: -0.5em;
  right: -1em;
  font-size: 1.4em;
  box-shadow: 0 0 0 2px var(--off-white);
  background-color: var(--off-white);
}

.app-gallery-full-image button:focus,
.app-gallery-full-image button:hover,
.app-gallery-full-image button:active {
  background-color: #fff;
  box-shadow: 0 0 0 2px #fff;
}


.app-content-with-media {
  display: grid;
  grid-gap: 1em;
}

.app-content-with-media .app-card {
  margin-bottom: 0;
}

.app-content-with-media .media-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.app-content-with-media.wrap-reverse .media-container {
  grid-row: 2;
}

.app-content-with-media .media-container > * {
  object-fit: cover;
  max-width: 100%;
  max-height: 30em;
  box-shadow: 0 0 5px 2px #aaa;
}

@media only screen and (min-width: 1024px) {
  .app-content-with-media {
    grid-template-columns: repeat(2, 1fr);
  }

  .app-content-with-media.wrap-reverse .media-container {
    grid-row: unset;
  }

}

@media only screen and (min-width: 1974px) { 
  .app-content {
    padding-left: calc((100vw - 1920px)/2);
    padding-right: calc((100vw - 1920px)/2);
  }

  .app-header-wrapper {
    margin-left: -0.5em;
  }

  .app-sub-nav {
    margin-left: calc((100vw - 1920px)/2 * -1);
    margin-right: calc((100vw - 1920px)/2 * -1);
  }
}



/**************************  home ****************************/
.home-content {
  display: flex;
  flex-direction: column;
  max-width: 50em;
}

.home-content .app-h1,
.home-content .app-lead-paragraph {
  order: -1;
}

.app-carousel {
  position: relative;
  height: 50vh;
  max-width: 100%;
  margin-bottom: var(--default-margin);
  padding: 2.7em 1.5em 3.5em 1.5em;
  text-align: center;
  overflow: hidden;
}

.app-carousel img {
  position: relative;
  top: 45%;
  transform: translate(0, -50%);
  display: none;
  max-height: 100%;
  max-width: 100%;
  box-shadow: 0 0 0 0.1875em var(--off-white), 0 0 0 0.3125em var(--base-color), 0 0 0 0.75em var(--off-white), 0 0 0 1.25em var(--primary);
  object-fit: scale-down;
}

.app-carousel img.current {
  display: inline-block;
}

.app-carousel-actions {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.app-carousel button {
  border: none;
  background: none;
  margin-top: 0.3em;
  padding: 0.5em;
  font-size: 0.6em;
  opacity: 0.5;
  cursor: pointer;
  color: inherit;
}

.app-carousel button.current {
  opacity: 1;
}

@media only screen and (min-width: 1200px) { 
  .home-content {
    display: block;
    max-width: none;
  }

  .home-content .app-card {
    max-width: 45%;
  }

  .app-carousel {
    float: right;
    height: 65vh;
    width: 50%;
    margin-left: var(--default-margin);
    margin-right: var(--default-margin);
  }
}

/**************************  about ****************************/

.about-us-intro {
  margin-bottom: calc(var(--default-margin) * 3);
  font-size: 1.266em;
}

.app-quote {
  width: 33em;
  max-width: calc(100% - 2.5em);
  margin: calc(var(--default-margin) * 2) auto var(--default-margin);
  background: #ddd;
  padding: 1em;
  box-shadow: var(--frame-shadow);
  font-size: 0.8em;
}

.app-quote blockquote {
  margin: 0;
}
